<template>
  <div :style="styleObject" class="page-header align-items-start min-vh-screen">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="card-body p-0 text-page pb-90">
            <div class="row">
              <div class="col-lg-2 col-md-2 col-2 pb-3 pt-3 custom-icon-back d-flex align-items-center">
                <a @click="backSignIn()"><i class="fas fa-chevron-left color-common" :style="`color: ${this.$store.state.labelColor} !important`"></i></a>
              </div>
              <div class="col-lg-8 col-md-8 col-8 color-common pb-3 pt-4 h4 text-center" :style="`color: ${this.$store.state.labelColor} !important`">
                TERMS OF SERVICE
              </div>
              <div class="col-lg-2 col-md-2 col-2 pt-2">
              </div>
            </div>
            <div class="row">
              <div class="text-title pb-3">
                Introduction
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 7/2/2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN: 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    The "Services" we provide or make available include (a) the Platform, (b) the features provided or introduced from time to time on the Platform and software made available through the Platform including all information, linked pages, features, data, text, images, photographs, graphics, music, sounds, video, messages, tags, content, programming, software, tools, application services (including, without limitation, any mobile application services) or other materials made available on or through the Platform or its related services ("Content"). Any new features added to or augmenting the Services are also subjected to these Terms of Use. These Terms of Use shall bind all users who use the Platform and/or any Services provided by us such as our customers and/ or consumers (“Users”, “User”, “you” or “your”).
                  </li>
                  <li>
                    Please do not use or access the Platform and/or our Services if you are under the age of eighteen (18) or the legal age for giving consent hereunder pursuant to the applicable laws in your jurisdiction (“Legal Age”). If you are below the Legal Age, you must get permission from a parent or legal guardian to open an Account and that parent or legal guardian must agree to these Terms of Use on your behalf.
                  </li>
                  <li>
                    If you are accessing the Platform and/or using our Services on behalf of a corporate body, you must ensure that you are duly authorised by the corporate body and have the full right and authority to access the Platform, open an Account and/or use our Services on behalf of the corporate body in accordance with these Terms of Use.
                  </li>
                  <li>
                    We reserve the right to change, modify, suspend or discontinue all or any part of the Platform and/or the Services at any time or upon notice as required by local laws. We may release new features in a beta or trial version, which may not work correctly or in the same way the final version may work, and we shall not be held liable in such instances. We may also impose limits on certain features or restrict your access to parts of, or the entire, Platform and/or Services in our sole discretion and without notice and any liability to you. WE MAY ALSO UPDATE THESE TERMS OF USE TO INCLUDE TERMS RELATING TO THE NEW FEATURES. YOUR CONTINUED USE OF THE NEW FEATURES SHALL CONSTITUTE YOUR ACKNOWLEDGEMENT, ACCEPTANCE AND AGREEMENT OF THESE TERMS.
                  </li>
                  <li>
                    We may at any time or from time to time sub-contract and/or appoint our subsidiaries, affiliates, related entities and/or any third party service provider(s) to operate the Platform and/or provide the Services and/or part thereof on our behalf at our absolute discretion. We shall have the rights to delegate, transfer, assign or novate, in whole or in part, our rights, benefits or obligations to our subsidiaries, affiliates, related entities or appointed third party service provider(s) without your consent and/or without notice to you.
                  </li>
                  <li>
                    WE MAY AMEND, MODIFY OR UPDATE THESE TERMS OF USE FROM TIME TO TIME. ANY CHANGE WE MADE TO THESE TERMS OF USE WILL BE PUBLISHED ON THE PLATFORM AND, WHERE APPROPRIATE, NOTIFIED TO YOU BY EMAIL OR VIA THE COMMUNICATION CHANNEL MADE AVAILABLE ON THE PLATFORM, WHEREUPON YOUR CONTINUED ACCESS TO THE PLATFORM, ACCOUNT THEREAFTER AND/OR USE OF ANY OF THE SERVICES SHALL CONSTITUTE YOUR ACKNOWLEDGMENT, ACCEPTANCE AND AGREEMENT OF THE CHANGE WE MADE TO THESE TERMS OF USE. PLEASE CHECK BACK FREQUENTLY TO SEE ANY UPDATES OR CHANGES TO THESE TERMS OF USE.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                Privacy Policy
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">MINTY CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    WE HAVE PROVIDED AND PUBLISHED A PRIVACY POLICY ON THE PLATFORM WHICH SETS OUT OUR COMMITMENTS IN DATA PROTECTION AND OUR PRIVACY POLICY IN DETAIL. PLEASE READ THE PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE COLLECT AND USE THE INFORMATION ASSOCIATED WITH YOUR ACCOUNT AND/OR YOUR USE OF THE SERVICES. BY USING THE SERVICES OR PROVIDING INFORMATION ON THE PLATFORM, YOU CONSENT TO OUR COLLECTION, USE, DISCLOSURE, STORAGE, TRANSFER AND/OR PROCESSING OF YOUR CONTENT AND DATA (INCLUDING PERSONAL DATA) AS DESCRIBED IN THE PRIVACY POLICY.
                  </li>
                </ol>
              </div>
              <div class="text-title-2 pb-3">
                Minty CRM Account
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRMmobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN )</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">MINTY CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <i class="text-content-2 pb-3">
                Registration of Account
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    In order to access and enjoy full functions and features of the Platform and/or our Services, you may be required to create and register for an account with us (“Account”) by providing certain personal or business information. You hereby acknowledge and agree that we may, whether acting on our own or our financial institution partners, conduct credit checks on you including but not limited to checking with registered credit bureau or reporting agency(ies) including but not limited to CCRIS, FIS and/or other agencies and/or from any financial institution for purpose of accessing your credit worthiness and you agree to execute all consent form(s) or to provide additional document(s) necessary for such credit check(s) if so required by us and/or our financial institution partners. You also acknowledge and agree that we and/or our financial institution partners may share information about you with relevant credit reporting/reference agencies, any for any of these credit reporting/reference agencies to disclose your credit information to its subscribers for purposes of fraud detection and fraud prevention.
                  </li>
                  <li>
                    You may create your Account by: 
                    (a) selecting a unique user identification (“<span class="text-emphasize">User ID</span>”) at your own discretion subject always to our approval or a User ID will be provided to you upon successful registration. You will be entitled to set the password and/or security code for your Account subject always to certain requirements as may be then notified or 
                    (b) connecting to an account maintained by you with a third party (such as Facebook, Google or your mobile service provider) wherein selected data of yours from such third party will be reflected in your Account. In registering with us, you agree to provide or that such information provided to us are accurate, current and complete information about yourself, and to update that information if it changes; if you don't, we have the right to close your Account and any web pages and/or other pages and/or reports created under your Account within Minty CRM. If we rely on the contents of your application and accept you as a Minty CRM’sUser, you irrevocably agree that you shall indemnify and keep us indemnified and hold us harmless for any expense, loss or damage that we may suffer arising from any inaccurate or false statement or misrepresentation of facts submitted by us to you. If you select a User ID that we, in our sole discretion, find offensive or inappropriate, we have the right to suspend or terminate your Account. 
                  </li>
                </ol>
              </div>
              <i class="text-content-2 pb-3">
                Security of Account
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    To safeguard the security of the Platform and your Account, you agree to:<br/>
                    (a) keep your password and/or security code confidential and use only your User ID, password and/or security code when logging in.<br/>
                    (b) ensure that you log out from your Account at the end of each session on the Platform;<br/>
                    (c) immediately notify us of any unauthorised use of your Account, User ID, password and/or security code;<br/>
                    (d) ensure that your Account information is accurate and up-to-date;<br/>
                    (e) use your Account only in accordance with the Terms of Use published (and updated from time to time) by us through online; and<br/>
                    (f) not use your Account in a manner or for any purposes which may detriment and jeopardize Minty CRM’s operation, interest, reputation or goodwill.
                  </li>
                  <li>
                    You are fully responsible for all activities and transactions that occur under your User ID and Account even if such activities or transactions were not committed by you as we are unable and not obliged to investigate the authenticity of user’s identity or authority of such person effecting an activity or transaction. We shall not be liable for any direct or indirect loss or damage arising from unauthorised use or access of your Account or your failure to comply with these Terms of Use.
                  </li>
                  <li>
                    For enhancement and improvement purposes, we may from time to time conduct software evaluation to assess the performance level of the Platform and/or Services. You hereby irrevocably grant to and authorise us to conduct periodical review on your Account, including but not limited to assessing the activities and/or transactions carried out by you on the Platform or via your Account.
                  </li>
                </ol>
              </div>
              <i class="text-content-2 pb-3">
                Identity Verification
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    We may from time to time require verification of your identity to confirm your ownership of your Account. This verification process is required for purposes including but not limited to:
                    (i) fulfilling our obligations to the relevant authorities to combat against suspicious or unlawful activities, anti-money laundering activities and the funding of terrorism;
                    (ii) enabling us to take action when unauthorised usage of Minty CRM accounts has been detected; and
                    (iii) resolving any disputes relating to the ownership of a Minty CRM account.
                  </li>
                  <li>
                    You hereby irrevocably authorise us, whether acting on our own or through our third party service providers and/or our financial institution partners, to make any inquiries and to collect any documentation that we and/or our financial institution partners consider necessary to verify your identity and/or verification of the source of your funds under the “Know-Your-Customer” principle. As part of this verification process, we and/or our financial institution partners may request, and you shall provide to us and/or our financial institution partners, information about yourself that is true, accurate, current and complete, and which can be verified through documentation that is legally recognized and accepted in the location in which such documentation is issued.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                Fees of using the platform
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the MINTY CRM website https://mintycrm.com.sg (“Site”) and/or Minty CRM mobile apps (“App”) (collectively, the “Platform”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">MINTY CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US. 
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    Unless otherwise informed in writing, we do not charge Users for accessing and browsing of the Platform.
                  </li>
                  <li>
                    We may impose fees for use of certain features on the Platform. Such fees shall be as stipulated in the Specific T&C which is specific to such feature. Please refer to the Specific T&C of the feature you intend to use for more information.
                  </li>
                </ol>
              </div>
              <div class="text-title-2 pb-3">
                Restricted use of Minty CRM’s intellectual property
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRMmobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "MINTY CRM", "WE", "US" OR "OUR") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US. 
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    We grant you a limited and revocable licence to access and use the incidental software provided by us to you via the Platform as part of the Services. Use of such software is subjected to these Terms of Use. Any third-party scripts or code, linked to or referenced from the Services, are licensed to you by the third parties that own such scripts or code. You shall not, directly or indirectly, modify the features or functionality of, copy or create derivative works using all or any portion of, analyse or remove components from, decompile, or otherwise reverse engineer or attempt to reverse engineer or derive source code, techniques, algorithms or processes from the software or permit or encourage any third-party to do so.
                  </li>
                  <li>
                    All proprietary Content, trademarks, service marks, brand names, logos, copyrighted information and other intellectual properties (“<span class="text-emphasize">Intellectual Property</span>”) displayed on the Platform are the exclusive property of Minty CRM and where applicable, third party proprietors such as Merchants (as defined in the Specific T&C section below). No right or licence is granted directly or indirectly to any party accessing the Platform to use or reproduce any Intellectual Property, and no party accessing the Platform shall claim any right, title or interest therein. By using or accessing the Services you agree to comply with the copyrights, trademarks, applicable intellectual property related legislations and all other applicable laws that protect the Services, the Platform and its Content. You agree not to copy, distribute, republish, transmit, publicly display, publicly perform, modify, adapt, rent, sell, or create derivative works of any portion of the Services, the Platform or its Content. You also may not, unless with our prior written consent, mirror or frame any part or whole of the contents of the Platform on any other server or as part of any other website. In addition, you agree that you will not use any robot, spider or any other automatic device or manual process to monitor or copy our Content, without our prior written consent (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this website).
                  </li>
                </ol>
                <ul>
                  <li>
                    <i class="text-i pb-3">
                      Third Party Links
                    </i>
                  </li>
                </ul>
              </div>
              <div class="text-content pb-3">
                Third party links may be provided throughout the Platform. These links are provided as a courtesy only, and the sites they link to are not under our control in any manner whatsoever and you therefore access them at your own risk. We are in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. We provide these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation, endorsement or sponsorship by us of any linked site and/or any of its content therein.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    Each contributor to the Services of data, text, images, sounds, video, software and other Content is solely responsible for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with their Content contribution. As such, we are not responsible to, and shall not, regularly monitor or check for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with any contribution of Content. You shall not hold us responsible for any other User's actions or inactions, including, without limitation, things they post or otherwise make available via the Services.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                User contents & undertakings
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the MINTY CRM website https://mintycrm.com.sg (“Site”) and/or Minty CRM mobile apps (“App”) (collectively, the “Platform”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">MINTY CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US. 
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    You understand that all Content, whether publicly posted or privately transmitted, is the sole responsibility of the person from whom such Content originated. This means that you, and not MINTY CRM, are entirely responsible for all Content that you uploaded, posted, emailed, transmitted or otherwise made available through or on the Platform.
                  </li>
                  <li>
                    You acknowledge that we and our designee(s) shall have the right (but not the obligation) in our sole discretion to pre-screen, refuse, delete, remove or move any Content, including without limitation any Content or information posted by you, that is available on the Platform. Without limiting the foregoing, we and our designee(s) shall have the right to remove any Content:<br/>
                    (a) that violates these Terms of Use;<br/>
                    (b) if we receive a complaint from another User;<br/>
                    (c) if we receive a notice of intellectual property infringement or other legal instruction for removal; or<br/>
                    (d) if such Content is otherwise objectionable.
                  </li>
                  <li>
                    We may also block delivery of a communication (including, without limitation, postings and/ or reviews) to or from the Services as part of our effort to protect the Services and/or our Users, or otherwise enforce the provisions of these Terms of Use. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including, without limitation, any reliance on the accuracy, completeness or usefulness of such Content. In this regard, you acknowledge that you have not and, to the fullest extent permitted by applicable law, may not rely on any Content made available by us or submitted to us.
                  </li>
                  <li>
                    You acknowledge that by accessing or using the Platform, you may be exposed to Content that you may consider to be offensive, indecent or objectionable. To the fullest extent permitted by applicable law, under no circumstances shall we be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of, or reliance on, any Content posted, emailed, transmitted or otherwise made available on the Platform.
                  </li>
                  <li>
                    You acknowledge, consent to and agree that we may access, preserve and disclose your Account information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over us or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce these Terms of Use; (c) respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, property or personal safety of Minty CRM, its Users and/or the public.
                  </li>
                  <li>
                    You shall not, and agree and undertake to Minty CRM not to:<br/>

                    (a) upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;<br/>
                    (b) use the Services to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;<br/>
                    (c) forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Services;<br/>
                    (d) remove or alter any proprietary notices from the Platform;<br/>
                    (e) cause, permit or authorise the modification, creation of derivative works, or translation of the Services without our express permission;<br/>
                    (f) use the Services for the benefit of any third party or any manner not permitted by the licenses granted herein or for fraudulent or illegal purposes;<br/>
                    (g) use the Services in a dishonest or fraudulent way to obtain any advantage, benefit or secret profit from any third party.<br/>
                    (h) take any action that may undermine or manipulate the feedback or ratings systems;<br/>
                    (i) attempt to decompile, reverse engineer, disassemble or hack the Services (or any portion thereof), or to defeat or overcome any encryption technology or security measures implemented by us with respect to the Services and/or data transmitted, processed or stored by us;<br/>
                    (j) harvest or collect any information about or regarding other Account holders, including, without limitation, any personal data or business information;<br/>
                    (k) upload, email, post, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);<br/>
                    (l) upload, email, post, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;<br/>
                    (m) upload, email, post, transmit or otherwise make available any unsolicited or unauthorised advertising, promotional materials, "junk mail", "spam", "chain letters", "pyramid schemes", or any other unauthorised form of solicitation;<br/>
                    (n) upload, email, post, transmit or otherwise make available any material that contains software viruses, worms, Trojan-horses or any other computer code, routines, files or programs designed to directly or indirectly interfere with, manipulate, interrupt, destroy or limit the functionality or integrity of any computer software or hardware or data or telecommunications equipment;<br/>
                    (o) interfere with, manipulate or disrupt the Services or servers or networks connected to the Services or any other User's use and enjoyment of the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Platform.<br/>
                    (p) take any action or engage in any conduct that could directly or indirectly damage, disable, overburden, or impair the Services or the servers or networks connected to the Services;<br/>
                    (q) use the Services to intentionally or unintentionally violate any applicable local, state, national or international law, rule, regulation, code, directive, guideline or policy including, without limitation, laws and requirements (whether or not having the force of law) relating to anti-fraud, anti-money laundering, counter-terrorism, unlawful proceeds and anti-corruption;<br/>
                    (r) use the Services in violation of or to circumvent any sanctions or embargo administered or laws enforced;<br/>
                    (s) use the Services to violate the privacy of others or to "stalk" or otherwise harass another;<br/>
                    (t) infringe the rights of Minty CRM, including any intellectual property rights and any passing off of the same thereof;<br/>
                    (u) reproduce, attempt to reproduce, counterfeit or resale any Merchant Offering (as defined in the Specific T&C section below) and/or any Cashback (as defined in the Specific T&C section below); and/or<br/>
                    (v) use the Services to mine, collect or store personal data about other Users in connection with the prohibited conduct and activities set forth above.<br/>
                  </li>
                  <li>
                    By submitting Content for inclusion on the Services, you represent and warrant that you have all necessary rights and/or permissions to grant the relevant licences to us. You further acknowledge and agree that you are solely responsible for anything you post or otherwise make available on or through the Services, including, without limitation, the accuracy, reliability, nature, rights clearance, compliance with laws and legal restrictions associated with any Content contribution. You hereby grant to us and our successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform such Content contribution on, through or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works) without need of attribution and you agree to unconditionally waive any moral rights (and any similar rights in any part of the world) in that respect. You understand that your contribution may be transmitted over various networks and changed to conform and adapt to technical requirements.
                  </li>
                  <li>
                    Any material, information or idea you provided to us by any means (each, a "<span class="text-emphasize">Submission</span>"), is not considered confidential by us and may be disseminated or used by us without compensation or liability to you for any purpose whatsoever, including, but not limited to, developing and marketing purposes. By making a Submission to us, you acknowledge and agree that we and/or other third parties may independently develop software, applications, interfaces, goods and modifications and enhancements of the same which are identical or similar in function, code or other characteristics to the ideas set out in your Submission. Accordingly, you hereby grant to us and our successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to develop the goods identified above, and to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform any Submission on, through or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works). This provision does not apply to personal information that is subject to our Privacy Policy except to the extent that you make such personal information publicly available on or through the Services.
                  </li>
                  <li>
                    You shall indemnify, defend and hold harmless Minty CRM and its subsidiaries, affiliates, related entities, directors, officers, agents, representatives, co-branders and employees from all actions, claims and demands which may be instituted or made against you arising from your use of third party’s intellectual property rights or violation of any applicable intellectual property laws.
                  </li>
                </ol>
              </div>
              <div class="text-title-2 pb-3">
                Disclaimers, exclusions and force majeure clauses
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRMmobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "MINTY CRM", "WE", "US" OR "OUR") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US. 
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <i class="text-content-2 pb-3">
                Disclaimers
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    THE PLATFORM AND/OR SERVICES ARE PROVIDED ON "AS IS" AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY US OF ANY KIND EITHER EXPRESSED, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, CUSTOM OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT THE PLATFORM AND/OR SERVICES OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THE PLATFORM AND/OR THE SERVER THAT MAKES THE SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS, COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL CODES, INSTRUCTIONS, PROGRAMS OR COMPONENTS.
                  </li>
                  <li>
                    WHILE WE ENDEAVOUR TO ENSURE THAT THE PLATFORM AND/OR SERVICES ARE SECURED, YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF THE ACCESS, USE OR PERFORMANCE OF THE PLATFORM AND/OR THE SERVICES SHALL REMAIN WITH YOU TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                  </li>
                </ol>
              </div>
              <i class="text-content-2 pb-3">
                Exclusions and Force Majeure
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE (WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY), OR OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE, FOR:<br/>
                    (A) -  (I) LOSS OF USE; (II) LOSS OF PROFITS; (III) LOSS OF REVENUES; (IV) LOSS OF DATA; (V) LOSS OF GOODWILL; OR (VI) FAILURE TO REALISE ANTICIPATED SAVINGS, IN EACH CASE WHETHER DIRECT OR INDIRECT; AND<br/>
                    (B) -  ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE PLATFORM OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING THEREFROM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </li>
                  <li>
                    WE SHALL NOT BE LIABLE FOR ANY DELAY OR FAILURE TO PERFORM THE SERVICES, WHERE THE CAUSE OF SUCH DELAY OR FAILURE IS BEYOND OUR REASONABLE CONTROL. SUCH CAUSES MAY INCLUDE BUT NOT LIMITED TO AN ACT OF GOD, RIOT, CIVIL COMMOTION, STRIKE (WHETHER OR NOT INVOLVING EMPLOYEES OF MINTY CRM), LOCKOUT OR OTHER LABOUR DISTURBANCE, FIRE, WAR, ACTS OF FOREIGN ENEMIES, POWER OUTAGE, PANDEMICS, EPIDEMICS, NETWORK CONGESTION, TELECOMMUNICATIONS FAILURE, ELECTRICAL POWER FAILURES, OR ANY FAULT, INTERRUPTION, DISRUPTION OR MALFUNCTION OF EQUIPMENT, TOOLS, UTILITIES, COMMUNICATIONS, COMPUTER (SOFTWARE AND HARDWARE) SERVICES OR NETWORKS, GOVERNMENT ORDER OR CHANGE IN ANY LAW OR REGULATION WHICH RENDERS THE PERFORMANCE IMPRACTICAL.
                  </li>
                  <li>
                    IF WE CONSIDER A FORCE MAJEURE EVENT TO BE OF SUCH SEVERITY OR TO BE CONTINUING FOR SUCH PERIOD OF TIME THAT WE ARE UNABLE TO PERFORM ANY OF OUR OBLIGATIONS, WE SHALL HAVE THE ABSOLUTE DISCRETION TO DECIDE ON SUCH ALTERNATIVE ARRANGEMENT(S), INCLUDING BUT NOT LIMITED TO TERMINATING THIS TERMS OF USE AND/ OR ANY SPECIFIC CONTRACT OR TRANSACTION (AS DEFINED IN SPECIFIC T&C SECTION BELOW) AND WITHOUT LIABILITY TO YOU OTHER THAN A REFUND OF PAYMENTS FOR SERVICES MADE BY YOU FOR WHICH IS NOT PERFORMED OR UNABLE TO BE PERFORMED.
                  </li>
                  <li>
                    YOU ACKNOWLEDGE, ACCEPT AND AGREE THAT ALL DISCLAIMERS, EXCLUSIONS AND LIMITATIONS OF LIABILITY SET OUT IN THESE TERMS OF USE REPRESENT A FAIR AND REASONABLE ALLOCATION OF RISKS AND BENEFITS OF THE AGREEMENT BETWEEN US AND YOU, TAKING ALL RELEVANT FACTORS INTO CONSIDERATION, INCLUDING WITHOUT LIMITATION THE VALUE OF THE CONSIDERATION PROVIDED BY YOU TO US AND THE AVAILABILITY AND COSTS OF INSURANCE WITH RESPECT TO THE STATED RISKS. YOU FURTHER AGREE THAT THESE DISCLAIMERS, EXCLUSIONS AND LIMITATIONS SHALL BE ENFORCEABLE TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                8. Feedback
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    We welcome information and feedback from you which will enable us to improve the performance of the Platform or quality of the Services provided. Please refer to our feedback procedure below for further information:
                  </li>
                  <li>
                    Feedback may be made in writing through email to info@mintycrm.com or such other communication channel(s) made available on the Platform.
                  </li>
                  <li>
                    Anonymous feedback will not be accepted.
                  </li>
                  <li>
                    Users affected by the feedback should be fully informed of all facts and given the opportunity to put forward their case.
                  </li>
                  <li>
                    Vague and defamatory feedback will not be entertained.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                9. Violation Of Terms Of Use
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    Any violation of these Terms of Use may result in a range of actions, including, without limitation, any or all of the following:<br/>
                    (a) Limits placed on Account privileges;<br/>
                    (b) Account suspension and subsequent termination; and/or<br/>
                    (c) Civil actions, including without limitation a claim for damages, specific performance and/or interim or injunctive relief. 
                  </li>
                  <li>
                    If you believe a User on our Platform is violating these Terms of Use, please contact us via email at info@mintycrm.com or through the communication channel(s) made available on the Platform.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                10. Suspension And Termination; Account Deactivation And Closure
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <i class="text-content-2 pb-3">
                Suspension and Termination
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    You agree that we may in our sole discretion, with or without notice, and without any liability to you or any third party, suspend or terminate your Account and your User ID, remove or discard from the Platform any Content associated with your Account and User ID, withdraw any promotions, offers, deals or subsidies offered to you, cancel any transaction associated with your Account and User ID, temporarily disable your access to any or all of the Services and/ or features on the Platform, withhold any and all sale proceeds or refunds (as the case may be), and/or take any other actions that we deem necessary. Typical reasons for such actions may include, but not limited to:<br/>
                    (a) the Account has been inactive for a consecutive period of twelve (12) months;<br/>
                    (b) having multiple user accounts or allowing unauthorised persons to access and use the Account;<br/>
                    (c) in our opinion, there is dishonesty, suspected fraud, illegality, criminality or misrepresentation in the conduct of your Account or your use of the Platform and/or Services;<br/>
                    (d) you are in breach or we have reasonable grounds to believe that you have breached any of these Terms of Use and/or any applicable terms and conditions as may be provided by us from time to time, or have engaged in any conduct prejudicial to Minty CRM or in our opinion, your acts are prejudicial to Minty CRM’s interest;<br/>
                    (e) you are in breach of any acts, statutes, laws, by-laws, rules, regulations, guidelines and/or policies imposed by any authority, regulatory body or government agency;<br/>
                    (f) you have acted in bad faith or with malicious intent, or that we have reasonable grounds to believe that your behaviour is harmful, of defamatory nature or abusive to other Users, third parties and/or Minty CRM;<br/>
                    (g) your name is listed under any regulatory watchlist (including but not limited to listing related to terrorism and terrorism financing) under the applicable laws governing anti-money laundering, anti-terrorism financing and/ or corruption and/or such other local, foreign or international laws and regulations of similar nature;<br/>
                    (h) if we are required to do so pursuant to an order of a court or by any governmental or regulatory authority having the relevant jurisdiction;<br/>
                    (i) you have submitted false documents or have declared false information during your registration with or application to us; and/or<br/>
                    (j) you fail to provide any additional information which we may request from you from time to time for verification purposes.<br/>
                    Use of the Platform, Services and/or an Account for suspicious, fraudulent, illegal, harassing, defamatory, threatening or abusive purposes may be referred by us to the relevant law enforcement authorities without notice to you.
                  </li>
                  <li>
                    If we suspend your Account, our Services and/ or any specific features to you pursuant to Clause 10.1 above, we shall have the right to commence investigation into such event and shall have the absolute right to disable your utilisation of any or all Services and/or hold back any and all refund or money or benefit due to you (as the case may be) until and unless clearance has been obtained from the relevant authorities and/or the satisfactory completion of any investigation.
                  </li>
                  <li>
                    In the event your access and/or utilisation of the Platform and/or Services is suspended, ceased or terminated by us due to suspicious, fraudulent, illegal or unlawful transactions including but not limited to breaches of any law or any rules, regulations, policies and/or guidelines made thereunder), you will not be able to continue to utilise any or all Services and shall not be entitled to obtain any money or refund or benefit whatsoever. It shall be lawful for us to retain for an indefinite period, or deal at its own discretion with, or release to the relevant authorities, any money or refund or benefit (including any Cashback issued to you) in accordance with applicable legislations, rules, regulation and/or guidelines. You shall not be entitled to claim any form of compensation for any loss arising therefrom.
                  </li>
                </ol>
              </div>
              <i class="text-content-2 pb-3">
                Consequences of Account Termination
              </i>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    Upon termination of the Account due to any reason whatsoever, unless otherwise agreed in writing by us, any and all unredeemed Merchant Offering or unutilised Cashback will be deemed to have expired immediately and be removed from your Account. Any outstanding payments due by you to us and/or Merchant shall become immediately due and payable and we shall be entitled to deduct the same from the payment methods made available by you to us on your Account without further consent from you. Further consequences shall be as stipulated in the Specific T&C.
                  </li>
                  <li>
                    Any provision of these Terms of Use which expressly or by implication is intended to continue to remain effective and binding on the User after the termination of Account and/or the termination of Services, such as provisions concerning the User’s warranties, undertakings and indemnifications to us, shall survive such termination.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                11. User's Representations And Warranties
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    By accessing the Platform, use of the Services and/or registering an Account with us, you irrevocably represent and warrant to us that:<br/>
                    (a) you possess the legal capacity (and in the case of a minor, valid parent or legal guardian’s consent), right and ability to accept and enter into these Terms of Use and to comply with all the terms and conditions contained herein;<br/>
                    (b) if you are representing and acting on behalf a corporate body, you possess the full authority, capacity, right and ability to accept and enter into these Terms of Use on behalf of the corporate body and to comply with all the terms and conditions contained herein;<br/>
                    (c) any and all information and documentations you provided or submitted to Minty CRM for whatsoever purposes are and will remain true and not fake, accurate, not misleading, complete and up-to-date;<br/>
                    (d) you will access and use the Platform and/or Services for lawful purposes only and in accordance with these Terms of Use and all applicable laws, rules, regulations, codes, directives, guidelines and policies in force from time to time; and<br/>
                    (e) if so required, you will make yourself available to, and to render full cooperation and assistance to MINTY CRM and/or the relevant authorities concerning any pending and future contentious matters, audits or investigations (including investigations commenced pursuant to Clause 10) arising out of or in connection with your Account or use of the Platform and/or Services in order to ensure that such matters or investigations can be resolved amicably and efficiently.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                12. Indemnity & Limitation Of Liability
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    You agree to indemnify, defend and hold us harmless, and our shareholders, subsidiaries, affiliates, related entities, directors, officers, employees, agents, representatives, co-branders and/or relevant business partners (collectively, the "<span class="text-emphasize">Indemnified Parties</span>") from and against any and all claims, actions, proceedings and suits and all related liabilities, damages, settlements, penalties, fines, costs and expenses (including, without limitation, the legal costs and dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to: (i) your violation or breach of any of these Terms of Use or any policy or guideline referenced herein, (ii) your use or misuse of the Platform or Services, or (iii) your breach of any laws or any rights of a third party.
                  </li>
                  <li>
                    In no event shall we be liable for loss of profit or goodwill, loss of data, loss of production or revenue or any type of special indirect or consequential loss whatsoever (including loss or damage suffered by the Customer as a result of an action brought by a third party) even if we had been advised of the possibility of incurring the same.
                  </li>
                  <li>
                    Notwithstanding any other provisions of these Terms of Use, our maximum cumulative liability to you or to any other party for all losses, arising out of or relating to your use of our Platform and/ or our Services, shall not exceed RM1,000.00 or its equivalent in your jurisdiction or the sum that you have paid to us under such individual contract or transaction, whichever is lower.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                13. Communications
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    All and any notices, demands, requests or other communications to be given or made under these Terms of Use shall be in writing, and shall be sufficiently given or made to the other party by serving such notice at or sending such notice by hand, registered post, facsimile, electronic mail or text messaging to the contact details as notified by one party to the other from time to time or via the communication channel made available on the Platform.
                  </li>
                  <li>
                    Notice shall be deemed given:<br/>
                    (a) in the case of hand delivery, upon the receipt of written acknowledgment signed by the recipient;<br/>
                    (b) in the case of registered post, five (5) business days after posting; and<br/>
                    (c) in case of facsimile, email, text messaging, or the communication channel(s) available on the Platform, on the day of transmission provided that the sender has not received a failed or undeliverable message from host provider of the recipient within the day of transmission or a read receipt is sent to and received by the sender.
                  </li>
                  <li>
                    Notwithstanding Clauses 13.1. and 13.2 above, you acknowledge that you may from time to time communicate with us verbally through phone conversation to facilitate your request or the resolution of any issue or problem faced by you. If any significant subject matter has been agreed between us and you verbally via a phone conversation, our customer service team may thereafter put the same into writing and such notice will be sent to you via email for record.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                14. Governing Law And Jurisdiction
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    These Terms of Use shall be governed by and construed in accordance with the laws of your jurisdiction without regard to the conflict or choice of law principles.
                  </li>
                  <li>
                    Any dispute, legal action or proceeding arising out of or in connection with these Terms of Use shall be submitted to the jurisdiction of the courts in your jurisdiction, unless MINTY CRM in its own discretion chooses to submit the same for settlement via arbitration in your jurisdiction or otherwise.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                15. General Provisions
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    If any provision of these Terms of Use shall be deemed invalid, unlawful, void or for any reason unenforceable under the law of any jurisdiction, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions in such jurisdiction nor the validity and enforceability of the provision in question under the law of any other jurisdiction. In such case, you acknowledge that we may introduce and come out with a substitute provision which is valid and enforceable and achieves to the greatest extent possible the economic, legal and commercial objectives of such illegal, void, invalid, prohibited or unenforceable term, condition, covenant or undertaking and you hereby agree to accept and be bound by such substitute provision.
                  </li>
                  <li>
                    You are not allowed to assign, transfer or sublicense any rights and benefits granted to you hereunder. We shall be entitled to transfer or assign any of its rights or obligations under these Terms of Use to a present or future affiliate or pursuant to a merger, consolidation, reorganisation or sale of all or substantially all of the assets or business, or by operation of law, without notice to you.
                  </li>
                  <li>
                    Nothing in these Terms of Use shall constitute a partnership, joint venture or principal-agent relationship between you and us, nor does it authorise you to make any representations or incur any costs or liabilities on our behalf.
                  </li>
                  <li>
                    Our failure to exercise or delay in exercising of any claim, remedy, right, power or privilege under these Terms of Use shall not be operated and deemed as a waiver thereof, or any single or partial exercise of any claim, remedy, right, power or privilege shall not preclude any other or further exercise thereof or the exercise of any other claim, remedy, right, power or privilege by us. Our rights and remedies provided in these Terms of Use are cumulative and not exclusive of any rights or remedies otherwise provided by law.
                  </li>
                  <li>
                    These Terms of Use are solely for your and our benefit and are not for the benefit of any other person or entity, except for our affiliates and subsidiaries (and each of our affiliates' and subsidiaries' respective successors and assigns).
                  </li>
                  <li>
                    The terms and conditions set forth in these Terms of Use and any additional terms and conditions or policies included or referred to in these Terms of Use constitute the entire agreement and understandings between you and us with respect to the Platform, Account and/or Services.
                  </li>
                  <li>
                    FAVE RESERVES ALL RIGHTS NOT EXPRESSLY GRANTED HEREIN.
                  </li>
                  <li>
                    These Terms of Use may be translated into any language(s) other than English (“Translated version”). In the event of any inconsistency between the English version and the Translated version, the English version shall prevail.
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                16. Specific T&C
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content pb-3">
                <ol>
                  <li>
                    The following section additionally governs the specific Services offered by us to you. Your use of such specific Services shall constitute acknowledgement, agreement and acceptance to such relevant sections (“Specific T&C”). Unless otherwise stated in the respective Specific T&C, the Specific T&C shall prevail if there is any inconsistency between a Specific T&C and Sections 1 to 15 in these Terms of Use.
                  </li>
                  <li>
                    DEFINITION & INTERPRETATION<br/>
                    (a) In this Section 16 (and its subsections), unless the subject or context otherwise requires the following words and expressions shall have the following meanings:
                    “<span class="text-emphasize">Cashback</span>” means, collectively, the MINTY CRM Cashback and the Partner Cashback.
                    "<span class="text-emphasize">Contract</span>" means the contract formed when we accept an order placed by you on the Platform on behalf of the Merchant for the purchase of Merchant Offering by issuance of a confirmation of successful purchase.
                    “<span class="text-emphasize">Minty CRM Cashback</span>” means the cashback awarded to you by us upon your utilization of Minty CRM subject to certain terms and conditions.
                    “<span class="text-emphasize">FINAL PRICE</span>” means the price payable by you:
                    (i) in relation to “Deals” under Section 16 below, as shall be as displayed on the confirmation page which shall include the price of the Merchant Offering, the applicable sales and services tax, value added tax and/ or other taxes (by whatever name called) imposed by the local authorities from time to time (if not already included in the price of the Merchant Offering) and such other applicable fee indicated; 
                    “<span class="text-emphasize">Merchant</span>” means a third-party merchant who partners with us to sell its Merchant Offering on the Platform, who shall provide or fulfil the Merchant Offering purchased and/or redeemed by you or who shall receive payment from you for goods and/ or services rendered or to be rendered to you.
                    "<span class="text-emphasize">Merchant Offering</span>" means goods, product or service, vouchers or eCards for the same offered by a Merchant and made available for sale by Fave on behalf of such Merchant on the Platform.
                    “<span class="text-emphasize">Partner Cashback</span>” means the cashback awarded to you by (i) a Merchant upon your utilization of Minty CRM for payments to the Merchant subject to certain terms and conditions set out by the Merchant or (ii) our partner following any marketing or promotion campaigns by our partner.<br/>
                    (b) For the purpose of calculating any period of time stipulated or when an act is required to be done within a specified period after or from a specified date, the period is inclusive of and time beings to run from the date specified
                  </li>
                </ol>
              </div>
              <div class="text-title pb-3">
                16. Terms & Conditions
              </div>
              <div class="text-content pb-3">
                <ul>
                  <li>
                    24 days ago
                  </li>
                  <li>
                    Updated
                  </li>
                </ul>
              </div>
              <i class="text-i pb-3">
                UPDATED AND EFFECTIVE AS OF 12 JANUARY 2023
              </i>
              <div class="text-content pb-3">
                Welcome to the Minty CRM website https://mintycrm.com.sg (“<span class="text-emphasize">Site</span>”) and/or Minty CRM   mobile apps (“<span class="text-emphasize">App</span>”) (collectively, the “<span class="text-emphasize">Platform</span>”). 
              </div>
              <div class="text-content pb-3">
                PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <span class="text-emphasize">THE APPLICABLE MINTY CRM COMPANY IN YOUR JURISDICTION (MEGASAFE TECHNOLOGY PTE LTD UEN 200503951K)</span> AND ITS RELATED COMPANIES, SUBSIDIARIES AND AFFILIATES (INDIVIDUALLY AND COLLECTIVELY, "<span class="text-emphasize">Minty CRM</span>", "<span class="text-emphasize">WE</span>", "<span class="text-emphasize">US</span>" OR "<span class="text-emphasize">OUR</span>") IN RESPECT OF THE ACCESS AND USE OF THE PLATFORM AND/OR SERVICES PROVIDED BY US.
              </div>
              <div class="text-content pb-3">
                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN MINTY CRM AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
              </div>
              <div class="text-content-2 pb-3">
                16.1 INTRODUCTION
              </div>
              <div class="text-content pb-3">
                The sale and purchase of any Merchant Offering on the Platform are additionally governed by these Specific T&C.
              </div>
              <div class="text-content-2 pb-3">
                16.2 BASIS OF THE CONTRACT
              </div>
              <div class="text-content pb-3">
                (a) The Merchant Offering on the Platform do not constitute an offer for sale but rather an invitation to treat. No Contract in respect of any item shall be formed until our issuance of a confirmation of successful purchase on behalf of the Merchant confirming your purchase of the Merchant Offering.<br/>
                (b) All Merchant Offering are subject to such further terms and conditions as may be specified accompanying such Merchant Offering on the Platform. Such further terms and conditions may include:
              </div>
              <div class="text-content-2 pb-3">
                (i) Validity or Redemption Period
              </div>
              <div class="text-content pb-3">
                The validity or redemption period of your Contract shall be as specified accompanying such Merchant Offering on the Platform. Upon the expiry of the validity or redemption period, the Contract shall be deemed to have lapsed and you shall not have any recourse against the Merchant and/or us.
              </div>
              <div class="text-content-2 pb-3">
                (ii) Additional Terms & Conditions, Fine Print and Things to know
              </div>
              <div class="text-content pb-3">
                Depending on the Merchant Offering purchased by you, additional terms and conditions will apply and shall be as specified accompanying such Merchant Offering on the Platform. Such additional terms and conditions would be published under headers including but not limited to “Additional Terms and Conditions”, “Fine Print” and “Things to know”. We and/ or the Merchant shall not be held liable for any costs, expenses or loss arising from your failure to take note of and/ or failure to comply with such additional terms and conditions.
              </div>
              <div class="text-content-2 pb-3">
                16.3 ORDERS AND SPECIFICATION
              </div>
              <div class="text-content pb-3">
                (a) You may make an offer to purchase Merchant Offering by placing and completing the order on the Platform and shall be responsible for ensuring the accuracy of the order. All orders shall be subject to our acceptance on behalf of the Merchant and each order accepted by us shall constitute a separate Contract.
                (b) Order acceptance and formation of the Contract will be completed upon our issuance of a confirmation of successful purchase of the Merchant Offering to you.<br/>
                (c)<span class="text-content-2"> Refusal of Order</span><br/>
                (i) We reserve the right to withdraw any item from the Platform at any time and/or remove or edit any materials or content on the Platform at its sole discretion.<br/>
                (ii) You acknowledge that we use our best efforts to always process all orders but there may be unforeseen circumstances where we are required to refuse to process an order despite a confirmation of successful purchase having been sent. In such an event, you agree that we shall not be held liable or in any way be held liable to you.<br/>
                (iii) In the event you have made full payment for your order under a Contract and if the refusal of order is not due to your fault, Minty CRM agrees to refund the payment made to the Customer if the refusal of order is not due to Customer’s fault.
                (d)<span class="text-content-2"> Permitted Dealings with Merchant Offering</span><br/>
                You may be permitted to deal with the Merchant Offering purchased in the manner provided on the Platform from time to time such as sending the Merchant Offering as a gift (“Permitted Dealings”) subject to such terms and conditions accompanying such Permitted Dealings on the Platform. Your instructions on the Permitted Dealings shall be deemed final and shall not be capable of cancellation or revocation unless permitted otherwise on the Platform.
              </div>
              <div class="text-content-2 pb-3">
                16.4 PRICE
              </div>
              <div class="text-content pb-3">
                (a) The price of Merchant Offering shall be the price listed on the Platform at the time which you place and complete the order on the Platform.<br/>
                (b) The FINAL PRICE payable by you shall be as displayed on the confirmation page which shall include the price of the Merchant Offering, the applicable sales and services tax, value added tax and/ or other taxes (by whatever name called) imposed by the local authorities from time to time (if not already included in the price of the Merchant Offering) and such other applicable fee indicated.<br/>
                (c) For the avoidance of doubt, all prices indicated on the Platform may be quoted in different currency depending on the jurisdiction where you are domiciled or logging in to the Platform.<br/>
                (d) In the event that any pricing has been mispriced on the Platform, we shall have the right to cancel the order or terminate the Contract which we shall inform you of such cancellation or termination via phone, by giving a written notice via email and/or the communication channel available in the Account.
              </div>
              <div class="text-content-2 pb-3">
                16.5 PAYMENT TERMS
              </div>
              <div class="text-content pb-3">
                (a) The FINAL PRICE payable shall be paid upon checkout. You will be entitled to make payments using the various payment methods made available on the Platform.<br/>
                (b) Our Platform supports one or more of the following payment methods:<br/>
                (i) Credit / Debit card;<br/>
                (ii) Online banking;<br/>
                (iii) Electronic wallet (e-wallet);<br/>
                (iv) Cashback; or<br/>
                (v) such other manner made available from our Platform from time to time.<br/>
                (c)<span class="text-content-2"> Cashback</span><br/>
                All payments by Minty CRM Cashback and/ or Partner Cashback shall be subject to certain further terms and conditions accompanying the issuance of such Cashback. Such further terms and conditions may include:<br/>
                (i)<span class="text-content-2"> Partner or Minty CRM Cashback Expiry</span><br/>
                The validity or redemption period of your Cashback shall be as specified accompanying the issuance of the Cashback on the Platform. Upon the expiry of the validity or redemption period, the Cashback shall be deemed to have lapsed and you shall not have any recourse against the Merchant, our partner and/or us.<br/>
                (ii)<span class="text-content-2"> Partner or Minty CRM Cashback Detail Breakdown</span><br/>
                Depending on the Cashback issued to you (whether Minty CRM Cashback or Partner Cashback), additional terms and conditions will apply and shall be as specified accompanying issuance of such Cashback on the Platform. Such additional terms and conditions may be published as “Partner Cashback Detail Breakdown”. We, our partner and/ or the Merchant shall not be held liable for any costs, expenses or loss arising from your failure to take note of or failure to comply with such additional terms and conditions.<br/>
                (iii) Notwithstanding all of the above, we reserve all rights to make final determination of the terms of redemption of such Cashback on a case to case basis.<br/>
                (d) Promo Codes<br/>
                We and/or the Merchant may accept certain Promo Codes in substitution of a portion of the price or FINAL PRICE. Such acceptance may be subject to further terms and conditions accompanying the issuance of such Promo Codes on a first come first serve basis including but not limited to acceptance of only a fixed number of such Promo Codes and that we and/or the Merchant shall be entitled to refuse acceptance of any such further Promo Codes.<br/>



                (i) We reserve the right to withdraw any item from the Platform at any time and/or remove or edit any materials or content on the Platform at its sole discretion.<br/>
                (ii) You acknowledge that we use our best efforts to always process all orders but there may be unforeseen circumstances where we are required to refuse to process an order despite a confirmation of successful purchase having been sent. In such an event, you agree that we shall not be held liable or in any way be held liable to you.<br/>
                (iii) In the event you have made full payment for your order under a Contract and if the refusal of order is not due to your fault, Minty CRM agrees to refund the payment made to the Customer if the refusal of order is not due to Customer’s fault.
                (d)<span class="text-content-2"> Permitted Dealings with Merchant Offering</span><br/>
                You may be permitted to deal with the Merchant Offering purchased in the manner provided on the Platform from time to time such as sending the Merchant Offering as a gift (“Permitted Dealings”) subject to such terms and conditions accompanying such Permitted Dealings on the Platform. Your instructions on the Permitted Dealings shall be deemed final and shall not be capable of cancellation or revocation unless permitted otherwise on the Platform.
              </div>
              <div class="text-content-2 pb-3">
                16A.6 WARRANTIES
              </div>
              <div class="text-content pb-3">
                (a) While we make every effort to ensure that all information and descriptions of Merchant Offering displayed on the Platform are accurate and complete, we provide the information and descriptions on an ‘as is’, ‘as available’ basis for informational purposes only and does not represent, warrant or guarantee that a Merchant Offering’s descriptions are accurate, complete, reliable, current or error-free. Depictions of a Merchant Offering are for reference only and may not always accurately depict every aspect of such Merchant Offering.<br/>
                (b) Save as expressly provided in these Clause 16A, all other warranties, guarantees, conditions or terms, including those implied by statute or common law, are excluded to the fullest extent permitted by law.<br/>
                (c) Your Acknowledgement<br/>
                You hereby irrevocably acknowledge and agree that:<br/>
                (i) we merely provide the Platform to facilitate the sale and purchase of the Merchant Offering by featuring the Merchant Offering on the Platform on behalf of the Merchant;<br/>
                (ii) all information about the Merchant Offering set out on the Platform are provided by and given to us by the Merchant;<br/>
                (iii) the Merchant is the ultimate seller and actual service provider of the Merchant Offering;<br/>
                (iv) the Merchant, and not us, is solely responsible for providing and/or fulfilling the Merchant Offering sold to and purchased by you upon your redemption at the Merchant’s participating outlet (online or in-store);<br/>
                (v) the Merchant is solely responsible to perform the warranty or honour the guarantee (if any) attached with the Merchant Offering;<br/>
                (vi) any dispute arising in connection with the provision, fulfilment or performance of the Merchant Offering shall be resolved between you and the Merchant only; and<br/>
                (vii) you have read and understood all additional terms and conditions accompanying the Merchant Offering, Cashback, Promo Codes, and/ or such other Services made available by us from time to time.<br/>
                If you have any questions or concerns about these Specific T&C – Minty CRM , please contact us via email at info@mintycrm.com or through the communication channel(s) made available on the Platform.
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import { useRoute } from 'vue-router'
export default {
  name: "terms-service",
  components: {
  },
  data() {
    return {
      merchantCode: "",
      phone: "",
      branchCode: "",
      tableCode: "",
      from: "",
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    const route = useRoute()
    this.phone = route.query.phone ?? '';
    this.branchCode = route.query.branch_code ?? '';
    this.tableCode = route.query.table_code ?? '';
    this.from = route.query.from ?? '';
  },
  computed: {
    styleObject() {
      return {
        '--label-color': this.$store.state.labelColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    backSignIn() {
      this.$router.push({ path: this.merchantCode + "/sign-in", query: { phone: this.phone, branch_code: this.branchCode, table_code: this.tableCode, from: this.from } });
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-page {
  text-align: justify;
  text-justify: inter-word;
}
.text-title {
    color: black !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.text-title-2 {
    font-size: 18px !important;
    color: #525252;
}

.text-i {
    color: black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.text-content {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.text-content-2 {
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.text-emphasize {
    color: black !important;
    font-weight: 500 !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

ol, ul {
  padding-left: 20px;
}
</style>
