<template>
  <div :style="styleObject" class="page-header align-items-start min-vh-screen">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="card-body p-0 text-page pb-90">
            <div class="row">
              <div class="col-lg-2 col-md-2 col-2 pb-3 pt-3 custom-icon-back d-flex align-items-center">
                <a @click="backSignIn()"><i class="fas fa-chevron-left color-common" :style="`color: ${this.$store.state.labelColor} !important`"></i></a>
              </div>
              <div class="col-lg-8 col-md-8 col-8 color-common pb-3 pt-4 h4 text-center" :style="`color: ${this.$store.state.labelColor} !important`">
                PRIVACY POLICY
              </div>
              <div class="col-lg-2 col-md-2 col-2 pt-2">
              </div>
            </div>
            <div class="row">
              <div class="text-title pb-3">
                Minty CRM Privacy Policy
              </div>
              <div class="text-content pb-3">
                Minty CRM ("We","Our",”Us”) takes your privacy seriously. This Privacy Policy describes how your Personal Data is collected, used, and shared when you visit our Site or make a purchase.
              </div>
              <div class="text-title pb-3">
                Minty CRM PDPA
              </div>
              <div class="text-content pb-3">
                Megasafe Technology Pte Ltd (Reg. No. 200503951K) and its subsidiaries (“Minty CRM”) recognizes its responsibilities in relation to the protection of the personal data collected, used, disclosed, processed, transferred or retained under the Personal Data Protection Act (“PDPA”).
                Personal data will be collected only for lawful and relevant purposes and protected from any unauthorized access, use and disclosure. Minty CRM takes reasonable steps to ensure that personal data held by it is accurate.
                Minty CRM may from time to time update this Privacy Policy to ensure it is consistent with our business requirements, industry trends and/or changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Policy as updated from time to time on Minty CRM’s website.
                Any updates to the Privacy Policy will be effective 30 days following the date on which this Privacy Policy is updated and posted on our website.
              </div>
              <div class="text-title pb-3">
                Collection of Personal Data
              </div>
              <div class="text-content pb-3">
                Personal Data refers to information about you, from which you are identifiable, including but not limited to your name, address(es), email address, organisation name, recipient name(s), and phone number(s). Sharing of your Personal Data is completely voluntary. However, do note that we may not be able to provide some of our services or accept payments without the required information.
                The collection of your Personal Data will generally occur when you provide your personal data to merchants using Minty CRM (“merchants”), for the purpose of, but not limited to:<br/>
                &emsp;Signing up of membership
              </div>
              <div class="text-title pb-3">
                Usage and Disclosure of Personal Data
              </div>
              <div class="text-content pb-3">
                We may use the Personal Data merchants have collected for the purpose of, but not limited to:<br/>
                &emsp;- Analytics<br/>
                &emsp;- To be contacted by Minty CRM or its clients via<br/>
                <ul>
                  <li>Email</li>
                  <li>SMS</li>
                  <li>Any other forms of communication by Minty CRM or its clients</li>
                </ul>
                End users of Minty CRM can opt out of selected or all forms of contact by Minty CRM or its clients.
              </div>
              <div class="text-content pb-3">
                We may also share the Personal Data collected by merchants to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, to protect the integrity of this Site, or to otherwise protect our rights.
              </div>
              <div class="text-title pb-3">
                Retention of Personal Data
              </div>
              <div class="text-content pb-3">
                Merchants and us may retain your personal data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws.
              </div>
              <div class="text-title pb-3">
                Withdrawal of Personal Data
              </div>
              <div class="text-content pb-3">
                You may withdraw your consent to provide Personal Data by submitting your request to the merchant which you have provided your Personal Data to.
              </div>
              <div class="text-title pb-3">
                Access and Correction of Personal Data
              </div>
              <div class="text-content pb-3">
                You have the right to access and update your Personal Data at any time. If you would like to correct or review the Personal Data about you, do contact the merchant to make the necessary amendments.
              </div>
              <div class="text-title pb-3">
                Changes
              </div>
              <div class="text-content pb-3">
                We may review and update the necessary in this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import { useRoute } from 'vue-router'
export default {
  name: "terms-service",
  components: {
  },
  data() {
    return {
      merchantCode: "",
      phone: "",
      branchCode: "",
      tableCode: "",
      from: "",
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    const route = useRoute()
    this.phone = route.query.phone ?? '';
    this.branchCode = route.query.branch_code ?? '';
    this.tableCode = route.query.table_code ?? '';
    this.from = route.query.from ?? '';
  },
  computed: {
    styleObject() {
      return {
        '--label-color': this.$store.state.labelColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    backSignIn() {
      this.$router.push({ path: this.merchantCode + "/sign-in", query: { phone: this.phone, branch_code: this.branchCode, table_code: this.tableCode, from: this.from } });
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-page {
  text-align: justify;
  text-justify: inter-word;
}
.text-title {
    color: black !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.text-title-2 {
    font-size: 18px !important;
    color: #525252;
}

.text-i {
    color: black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.text-content {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.text-content-2 {
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.text-emphasize {
    color: black !important;
    font-weight: 500 !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}
</style>
